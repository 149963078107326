import { Skeleton } from "primereact/skeleton";
import React, { useEffect, useState } from "react";
import "./InputText.scss";

const InputText = ({
    placeholder,
    id,
    name,
    onChange,
    value,
    errors,
    nameTitle,
    className,
    disabled,
    required=true,
    onBlur,
    autoComplete='on',
    hideRequired,
    pattern,
    width,
    skeleton,
    requiredLabel,
    onKeyDown,
}) => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [iconsChange, setIconchange] = useState(true);
    const togglePassword = () => {
        setPasswordShown(!passwordShown);
        setIconchange(!iconsChange);
    };
    return ( !skeleton ?
        <>
            <div className={`cj-input-text-password ${className} ${hideRequired && "hideRequired"} ${disabled && "disabledInput"}`} style={{width: width}}>
                {
                    !requiredLabel ?
                    <div className="top-container">
                        <div className="title">{nameTitle}</div>
                        {
                            required ?
                                <>{errors ? (
                                    <div className="obligatory red" id={id + 'obligatory'}>
                                        Obligatorio
                                    </div>
                                ) : (
                                    <div className="obligatory" id={id + 'obligatory'}>
                                        Obligatorio
                                    </div>
                                )}</>
                                :
                                <div className="obligatory" id={id + 'obligatory'}>
                                    Opcional
                                </div>
                        }
                    </div>
                    : ''
                }
                {errors ? (
                    <div className="input-wrapper">
                        <input
                            id={id}
                            name={name}
                            value={value}
                            onChange={onChange}
                            className="input"
                            placeholder={placeholder}
                            type={"text"}
                            disabled={disabled}
                            onBlur={onBlur}
                            autoComplete="off"
                            maxLength={pattern}
                            onKeyDown={onKeyDown}
                            style={{ border: '1px solid red', borderRadius: '5px' }}
                        />
                    </div>
                ) : (
                    <div className="input-wrapper">
                        <input
                            id={id}
                            name={name}
                            value={value}
                            onChange={onChange}
                            className="input"
                            placeholder={placeholder}
                            type={"text"}
                            disabled={disabled}
                            onBlur={onBlur}
                            autoComplete={autoComplete}
                            maxLength={pattern}
                            onKeyDown={onKeyDown}
                        />
                    </div>
                )}
            </div>
        </>

        : <Skeleton width={width || "100%"} className={className} height="73px" />
    );
};
export default InputText;
