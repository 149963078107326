//styles
import "./Header.scss"

//images
import logo from "../../../img/logo_codeb.png"
import facebook from "../../../img/icons/facebook.svg"
import likendin from "../../../img/icons/likendin.svg"
import instagram from "../../../img/icons/instagram.png"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Skeleton } from "primereact/skeleton"
import mexico from '../../../img/icons/mexico.svg';
import us from '../../../img/icons/us.svg';

const Header = ({ skeleton }) => {
    const [ind, setInd] = useState('home')
    const options = [  //Header options
        { title: "Home", id: "home" },
        { title: "About us", id: "us" },
        { title: "Services", id: "services" },
        { title: "Projects", id: "projects" },
        { title: "Team", id: "team" },
        { title: "Contact us", id: "contact" },
    ]

    // function to go to a selected id of the page
    const scrollToMyRef = (id) => {
        document.getElementById(`${id}`)?.scrollIntoView({ behavior: "smooth" });
        setInd(id)
    };




    return (
        !skeleton ? (
            <div className='Header'>
                <div className="container">
                    <div className="ChagueIdiom">
                        <div>
                            <Link to={"https://businesscodebuilders.com/"}>
                                <img src={mexico} width={"30"} alt="" />
                            </Link>
                        </div>
                        <div>
                            <Link to={"https://en.businesscodebuilders.com/"}>
                                <img src={us} width={"30"} alt="" />
                            </Link>
                        </div>
                    </div>
                    <img className="logo" alt="code builders" src={logo} onClick={() => scrollToMyRef("home")} />
                    <div className="options">

                        {/* Iteration of Header options */}
                        {options.map((e, index) => {
                            return (
                                <h3 onClick={() => scrollToMyRef(e.id)}>{e.title}</h3>
                            )
                        })}
                    </div>
                    <div className="icons">
                        <Link to="https://www.linkedin.com/in/code-builders-2023cdbl" target="_blank">
                            <img alt="" src={likendin} />
                        </Link>
                        <Link to="https://www.facebook.com/profile.php?id=100091074854163" target="_blank">
                            <img alt="" src={facebook} />
                        </Link>
                        <Link to="https://www.instagram.com/codebuilders2023/" target="_blank">
                            <img alt="" src={instagram} />
                        </Link>
                    </div>
                </div>
            </div>
        ) : (
            <div className='Header'>
                <div className="container">
                    <Skeleton width='150px' height="40px" />
                    <div className="options">

                        {/* Iteration of Header options */}
                        {options.map((e, index) => {
                            return (
                                <Skeleton width='70px' height="25px" />
                            )
                        })}
                    </div>
                    <div className="icons">
                        <Skeleton width='25px' height="25px" borderRadius="50%" />
                        <Skeleton width='25px' height="25px" borderRadius="50%" />
                        <Skeleton width='25px' height="25px" borderRadius="50%" />
                    </div>
                </div>
            </div>)
    )
}

export default Header