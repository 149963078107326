import React, { Suspense } from "react";
import { BrowserRouter as Router,Routes, Route, HashRouter } from "react-router-dom";
import MainPage from "../../pages/MainPage/MainPage";
import PricePackages from "../../pages/PricePackages/PricePackages";
import About from "../../pages/About/About";

const MainRouter = ()=>
{
    return(
        <>
       
            <HashRouter>
                <Routes>
                    <Route
                        path="/"
                        element = {<Suspense fallback={<>Skeleton</>}>
                            <MainPage/> 
                        </Suspense>}
                    />
                     <Route
                        path="price-packages"
                        element = {<Suspense fallback={<>Skeleton</>}>
                            <PricePackages></PricePackages>
                        </Suspense>}
                    />
                    <Route
                        path="/about"
                        element = {<Suspense fallback={<>Skeleton</>}>
                            <About></About>
                        </Suspense>}
                    />
                </Routes>

            </HashRouter>
        </>
    )
}
export default MainRouter

