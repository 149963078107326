//styles
import "./Goals.scss"

//images
import image from "../../../img/grafico_code_builders.png"

const Goals = () => {

    // function to take to a certain ID
    const gotToID = () => {
        const element = document.getElementById('contact');
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }


    return (
        <div className='Goals' id="us">
            <div className="mainContainer">
                <div className="image">
                    <img alt="" src={image} />
                </div>
                <div className="context">
                    <h1>Code Builders</h1>
                    <p>
                        We provide custom software solutions
                        and high quality to our customers, driven by
                        innovation and excellence in technology.
                    </p>
                    <p>

                        Buscamos constantemente superar las expectativas
                        de nuestros clientes y establecer relaciones duraderas basadas en la confianza y la satisfacción mutua.
                    </p>
                    <p>
                        We strive to be a leading company in the software development market
                    </p>
                    <button aria-label="contact us" className="btnServices" onClick={() => gotToID()}>
                        contact us
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Goals