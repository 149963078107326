import React, { useState } from "react";
import Services from "../../components/molecules/Services/Services";
import Goals from "../../components/organisms/Goals/Goals";
import HomePage from "../../components/organisms/Home/HomePage";
import './MainPage.scss'
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import ModalWhatsApp from "../../components/molecules/ModalWhatsApp/ModalWhatsApp";
import watsapp from '../../img/icons/ws.svg'
import TeamContact from "../../components/organisms/TeamContact/TeamContact";
import TeamAndContactUs from "../../components/molecules/TeamAndContactUs/TeamAndContactUs";
import Footer from "../../components/molecules/Footer/Footer";
import GoalPage from "../../components/organisms/Goals/GoalPage";
import { Link, redirect, useNavigate } from "react-router-dom";

const MainPage = () => {
    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <Link to='https://wa.me/+5213344703129'>
                <img src={watsapp} className="ws" />
            </Link>
            <div className="MainPage">
                <HomePage></HomePage>
                <GoalPage></GoalPage>
                <Services id={'services'}></Services>
                {/* <TeamContact /> */}
                <TeamAndContactUs></TeamAndContactUs>
                <Footer></Footer>
            </div>
            {openModal ? <ModalWhatsApp setOpenModal={setOpenModal}></ModalWhatsApp> : ""}
        </>
    )
}
export default MainPage