import React, { useState } from "react";
import './ModalWhatsApp.scss'

const ModalWhatsApp = ({ setOpenModal }) => {
    const [showInfo, setShowInfo] = useState(false)
    const showMessage = () => {
        setShowInfo(true)

        setTimeout(() => {
            setOpenModal(false)
        }, 2500)
    }
    return (
        <>
            <div className="ModalWhatsApp">
                {
                    !showInfo ?
                        <>
                            <div className="message-container">
                                <div>Mensajes</div>
                                <div style={{ color: "red", fontWeight: "bold", cursor: "pointer" }} onClick={() => { setOpenModal(false) }}>X</div>
                            </div>
                            <textarea placeholder="Escribe tu mensaje" className="text-message"></textarea>
                            <button aria-label="send" style={{cursor:"pointer"}} onClick={() => { showMessage() }}>ENVIAR</button>
                        </> :
                        <>
                            <div className="message-container">
                                <div>Mensajes</div>
                                <div style={{ color: "red", fontWeight: "bold" }} onClick={() => { setOpenModal(false) }}>X</div>
                            </div>
                            <div className="content-message-send">
                                En breve contestaremos su mensaje
                            </div>
                        </>
                }
            </div>
        </>
    )
}
export default ModalWhatsApp